import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "landing",
  class: "h85 d-flex align-items-center bg-gradient2 section-padding"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  style: {"text-align":"center"},
  class: "my-5"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_component_spinner, {
            text: "Estamos procesando tu solicitud, espera un momento.",
            color: _ctx.spinnerColor,
            textColor: _ctx.spinnerTextColor
          }, null, 8, ["color", "textColor"])
        ], 512), [
          [_vShow, _ctx.loading]
        ]),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: _ctx.html
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}